import React from 'react';
import SvgIcon from '../SvgIcon';

const StyledComponentsIcon = (props) => (
    <SvgIcon viewBox="0 0 512 512" {...props}>
        <path
            fill="currentColor"
            d="M128.392 245.844c-2 25.182 20.123 43.73 35.221 43.73c15.119-.705 74.002-50.326 74.002-50.326c27.694-35.025 27.814-50.599 27.814-62.84c-2.31-54.043-100.77 28.27-137.037 69.436zm243.565-20.918c13.365-28.286-4.616-59.703-43.728-32.108c-31.08 19.754-71.317 60.392-90.574 76.3c-8.932 18.041 13.71 67.551 49.59 41.41c42.41-30.812 73.38-59.785 84.712-85.602zm36.4-171.112L416.97 0H512v98.234l-34.827.416l-41.935 142.034c2.61 50.128-22.81 50.944-26.057 73.945c-8.161 57.81-72.81 105.112-97.527 105.112c-16.485-.478-28.536-19.372-32.584-35.836c46.48-51.41 108.984-91.811 108.984-91.811c3.903-27.037-4.675-35.645 24.173-58.684l27.528-144.738c-23.51-15.525-34.48-15.284-31.398-34.858zm-185.487 239.7c24.102 48.564 58.927 49.83 112.92-11.999c1.283 5.716 2.688 11.705.11 26.085C226.064 405.839 122.614 501.45 91.125 512H0v-24.44c14.82-20.552 136.448-120.871 222.87-194.046zm162.328 94.03c-4.945 42.036-43.27 78.82-94.482 124.456H128.412L265.24 396.745c23.62 53.755 65.574 45.968 119.959-9.201zM193.26 292.13L0 456.854v-106.87L113.73 257.4c6.084 34.461 43.355 64.806 79.53 34.732z"
        />
    </SvgIcon>
);

export default StyledComponentsIcon;
