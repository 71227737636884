import React from 'react';
import SvgIcon from '../SvgIcon';

const LinkedinIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M6.94 5a2 2 0 1 1-4-.002a2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z" />
    </SvgIcon>
);

export default LinkedinIcon;
