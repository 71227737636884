import React from 'react';
import {
    AngularIcon,
    CssIcon,
    DockerIcon,
    FirebaseIcon,
    GatsbyIcon,
    GitIcon,
    GraphQLIcon,
    HtmlIcon,
    JavaScriptIcon,
    MuiIcon,
    NodejsIcon,
    ReactIcon,
    StyledComponentsIcon,
    TypeScriptIcon,
    WebpackIcon,
} from '@components/icons';
import { TOOL_COLOR } from './tools';

export const SKILLS = [
    {
        id: 'html',
        name: 'HTML',
        Icon: <HtmlIcon />,
        color: TOOL_COLOR.html,
        url: 'https://html.spec.whatwg.org/',
    },
    {
        id: 'css',
        name: 'CSS',
        Icon: <CssIcon />,
        color: TOOL_COLOR.css,
        url: 'https://www.w3.org/TR/CSS/',
    },
    {
        id: 'js',
        name: 'JavaScript',
        Icon: <JavaScriptIcon />,
        color: TOOL_COLOR.js,
        url: 'https://ecma-international.org/publications-and-standards/standards/ecma-262/',
    },
    {
        id: 'ts',
        name: 'TypeScript',
        Icon: <TypeScriptIcon />,
        color: TOOL_COLOR.ts,
        url: 'https://www.typescriptlang.org/',
    },
    {
        id: 'nodejs',
        name: 'Node.js',
        Icon: <NodejsIcon />,
        color: TOOL_COLOR.nodejs,
        url: 'https://nodejs.org/',
    },
    {
        id: 'react',
        name: 'React',
        Icon: <ReactIcon />,
        color: TOOL_COLOR.react,
        url: 'https://react.dev/',
    },
    {
        id: 'gatsby',
        name: 'Gatsby',
        Icon: <GatsbyIcon />,
        color: TOOL_COLOR.gatsby,
        url: 'https://www.gatsbyjs.com/',
    },
    {
        id: 'angular',
        name: 'Angular',
        Icon: <AngularIcon />,
        color: TOOL_COLOR.angular,
        url: 'https://angular.io/',
    },
    {
        id: 'graphql',
        name: 'GraphQL',
        Icon: <GraphQLIcon />,
        color: TOOL_COLOR.graphql,
        url: 'https://graphql.org/',
    },
    {
        id: 'git',
        name: 'Git',
        Icon: <GitIcon />,
        color: TOOL_COLOR.git,
        url: 'https://git-scm.com/',
    },
    {
        id: 'webpack',
        name: 'Webpack',
        Icon: <WebpackIcon />,
        color: TOOL_COLOR.webpack,
        url: 'https://webpack.js.org/',
    },
    {
        id: 'firebase',
        name: 'Firebase',
        Icon: <FirebaseIcon />,
        color: TOOL_COLOR.firebase,
        url: 'https://firebase.google.com/',
    },
    {
        id: 'docker',
        name: 'Docker',
        Icon: <DockerIcon />,
        color: TOOL_COLOR.docker,
        url: 'https://www.docker.com/',
    },
    {
        id: 'mui',
        name: 'MUI',
        Icon: <MuiIcon />,
        color: TOOL_COLOR.mui,
        url: 'https://mui.com/',
    },
    {
        id: 'styled-components',
        name: 'Styled-components',
        Icon: <StyledComponentsIcon />,
        color: TOOL_COLOR['styled-components'],
        url: 'https://styled-components.com/',
    },
];
